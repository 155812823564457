import React from 'react'
import {provider, useInstance} from 'react-ioc'
import {RouterProvider} from 'react-router-dom'
import {App as AntApp, ConfigProvider as AntConfigProvider, Spin} from 'antd'
import {extend} from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import {observer} from 'mobx-react-lite'
import {createRoot} from 'react-dom/client'
import AntAppActions from 'components/AntAppActions'
import {LANGUAGE} from 'config/language'
import {ROUTER} from 'config/routes'
import {getAntLocale} from 'lib/helpers/locale'
import {AnalyticsEventService} from 'new/services/analytics/AnalyticsEventService'
import {CookieStorageService} from 'new/services/CookieStorageService'
import {ThemeStore} from 'new/stores/ThemeStore'
import styles from 'assets/styles/App.module.scss'
import 'assets/styles/index.scss'

////////////////////////////////////////////////////////////////////////////////

extend(relativeTime)
extend(customParseFormat)

////////////////////////////////////////////////////////////////////////////////

window.rnw ??= {}
window.rnw.hub = true

////////////////////////////////////////////////////////////////////////////////

const App = provider(
  //
  AnalyticsEventService,
  CookieStorageService,
  ThemeStore
)(
  observer(() => {
    const themeStore = useInstance(ThemeStore)
    const {themeConfig} = themeStore
    const locale = getAntLocale(LANGUAGE.get())

    return (
      <section className={styles.app}>
        <AntConfigProvider theme={themeConfig} locale={locale}>
          <AntApp>
            <AntAppActions />
            <RouterProvider
              router={ROUTER}
              fallbackElement={<Spin size="large" />}
            />
          </AntApp>
        </AntConfigProvider>
      </section>
    )
  })
)

////////////////////////////////////////////////////////////////////////////////

export const runApp = () => {
  const root = createRoot(document.querySelector<HTMLElement>('#root')!)
  root.render(<App />)
}

////////////////////////////////////////////////////////////////////////////////

try {
  runApp()
} catch (error) {
  // eslint-disable-next-line no-console
  console.error('Could not load app')
  document.querySelector<HTMLElement>('#root')!.innerHTML +=
    'We don’t officially support your browser. Please, try using a recent version of Firefox, Safari, or Chrome.'
}
