import React, {
  type FC,
  type PropsWithChildren,
  type ReactNode,
  useEffect,
} from 'react'
import {useTranslation} from 'react-i18next'
import {CloseCircleFilled, ExclamationCircleFilled} from '@ant-design/icons'
import {Alert, Form} from 'antd'
import {type FormItemProps as AntFormItemProps} from 'antd/lib/form/FormItem'
import {get} from 'lodash-es'
import {observer} from 'mobx-react-lite'
import {getFieldValidationTranslation} from 'lib/helpers/getFieldValidationTranslation'
import {useNamespace} from 'lib/hooks/useNamespace'
import {isFieldRequired} from 'lib/validator'
import {useFormHandler} from 'new/hooks/useFormHandler'
import styles from './index.module.scss'

///////////////////////////////////////////////////////////////////////////////

export type FormItemBaseProps = PropsWithChildren<{
  name: string
  label?: ReactNode
  skipLabel?: boolean
  labelIcon?: ReactNode
  required?: boolean
  registerOnMount?: boolean
  unregisterOnUnmount?: boolean
  untouchOnUnmount?: boolean
  resetOnUnmount?: boolean
  disabled?: boolean
  errorMessageType?: ErrorMessageType
  showErrorOnUntouched?: boolean
  showWarningOnUntouched?: boolean
  skipError?: boolean
  hideErrorMessage?: boolean
  htmlErrorMessage?: boolean
}>

export type FormItemProps = FormItemBaseProps & AntFormItemProps

export enum ErrorMessageType {
  TEXT = 'text',
  ALERT = 'alert',
}

///////////////////////////////////////////////////////////////////////////////

export const FormItem: FC<FormItemProps> = observer(props => {
  // eslint-disable-next-line prefer-const
  let {ns, prefix} = useNamespace()
  const {t} = useTranslation(ns)
  const form = useFormHandler()
  const {
    initialValues,
    validationErrors,
    warnErrors,
    touchedFields,
    validationConstraints,
  } = form

  /* eslint-disable prefer-const */
  let {
    name,
    label,
    skipLabel,
    labelIcon,
    required,
    registerOnMount,
    unregisterOnUnmount,
    untouchOnUnmount,
    resetOnUnmount,
    disabled,
    errorMessageType,
    showErrorOnUntouched,
    showWarningOnUntouched,
    skipError,
    hideErrorMessage,
    htmlErrorMessage,
    children,
    ...rest
  } = {...FORM_ITEM_DEFAULT_PROPS, ...props}
  /* eslint-enable prefer-const */

  useEffect(() => {
    if (registerOnMount) {
      form.setFieldRegistered(name, true)
    }

    return () => {
      if (unregisterOnUnmount) {
        form.setFieldRegistered(name, false)
      }

      if (untouchOnUnmount) {
        form.setFieldTouched(name, false)
      }

      if (resetOnUnmount) {
        const initialValue: string = get(initialValues, name, '')
        form.setFieldValue(name, initialValue)
      }
    }
  }, [])

  if (!skipLabel && !label) {
    prefix = prefix ? `${prefix}.` : ''
    label = t(`${prefix}field_labels.${name}`)
  }

  const isTouched = touchedFields.includes(name)
  const hasError = !skipError && validationErrors && !!validationErrors[name]
  const hasWarning = !skipError && warnErrors && !!warnErrors[name]

  const showError = hasError && (showErrorOnUntouched ? true : isTouched)
  const showWarning =
    !hasError && hasWarning && (showWarningOnUntouched ? true : isTouched)

  if (showError) {
    const message = getFieldValidationTranslation(t, {
      name,
      error: validationErrors[name][0],
      isHTML: htmlErrorMessage,
    })

    rest.validateStatus = 'error'

    if (errorMessageType === ErrorMessageType.ALERT) {
      rest.help = (
        <Alert
          className={styles.alert}
          message={message}
          type="error"
          showIcon
          icon={<CloseCircleFilled />}
        />
      )
    } else {
      rest.help = htmlErrorMessage ? (
        <div dangerouslySetInnerHTML={{__html: message ?? ''}} />
      ) : (
        message
      )
    }

    if (hideErrorMessage) {
      rest.help = null
    }
  }

  if (showWarning) {
    const message = getFieldValidationTranslation(t, {
      name,
      error: warnErrors[name][0],
    })
    rest.validateStatus = 'warning'
    rest.help = (
      <Alert
        className={styles.alert}
        message={message}
        type="warning"
        showIcon
        icon={<ExclamationCircleFilled />}
      />
    )
  }

  if (!required) {
    required = isFieldRequired(name, validationConstraints)
  }

  return (
    <Form.Item
      {...rest}
      colon={false}
      label={
        label && (
          <>
            {label}
            {!required && !disabled && (
              <>{' ' + t('common:field_labels:optional_postfix')}</>
            )}
            {labelIcon && <span className={styles.labelIcon}>{labelIcon}</span>}
          </>
        )
      }
    >
      {!label && labelIcon && (
        <span className={styles.labelIcon}>{labelIcon}</span>
      )}
      {children}
    </Form.Item>
  )
})

export const FORM_ITEM_DEFAULT_PROPS: Partial<FormItemBaseProps> = {
  labelIcon: null,
  required: false,
  registerOnMount: true,
  unregisterOnUnmount: true,
  untouchOnUnmount: true,
  resetOnUnmount: false,
  errorMessageType: ErrorMessageType.TEXT,
  showErrorOnUntouched: false,
  showWarningOnUntouched: true,
}
