import React, {
  type ChangeEventHandler,
  type FocusEventHandler,
  forwardRef,
} from 'react'
import {useTranslation} from 'react-i18next'
import {Input as AntInput} from 'antd'
import {type InputProps as AntInputProps} from 'antd/lib/input'
import {get} from 'lodash-es'
import {observer} from 'mobx-react-lite'
import {useNamespace} from 'lib/hooks/useNamespace'
import {useFormHandler} from 'new/hooks/useFormHandler'
import {
  FORM_ITEM_DEFAULT_PROPS,
  FormItem,
  type FormItemBaseProps,
} from './FormItem'

///////////////////////////////////////////////////////////////////////////////

export type TextProps = FormItemBaseProps & AntInputProps

///////////////////////////////////////////////////////////////////////////////

export const Text = observer(
  forwardRef<any, TextProps>((props, ref) => {
    // eslint-disable-next-line prefer-const
    let {ns, prefix} = useNamespace()
    const {t} = useTranslation(ns)
    const form = useFormHandler()
    const {initialValues, values} = form

    /* eslint-disable prefer-const */
    let {
      name,
      label,
      skipLabel,
      labelIcon,
      registerOnMount,
      unregisterOnUnmount,
      untouchOnUnmount,
      resetOnUnmount,
      disabled,
      placeholder,
      errorMessageType,
      showErrorOnUntouched,
      showWarningOnUntouched,
      skipError,
      ...rest
    } = {...TEXT_DEDFAULT_PROPS, ...props}
    /* eslint-enable prefer-const */

    const initialValue: string = get(initialValues, name, '')
    const value: string = get(values, name, '')

    if (!placeholder) {
      prefix = prefix ? `${prefix}.` : ''
      placeholder = t(`${prefix}placeholders.${name}`, {defaultValue: ''})
    }

    const onChange: ChangeEventHandler<HTMLInputElement> = event => {
      form.setFieldValue(name, event.target.value)
    }

    const onBlur: FocusEventHandler<HTMLInputElement> = () => {
      const trimmedValue = value.trim()
      if (trimmedValue !== value) {
        form.setFieldValue(name, trimmedValue)
      }
      if (trimmedValue !== initialValue) {
        form.setFieldTouched(name, true)
      }
    }

    return (
      <FormItem
        name={name}
        label={label}
        skipLabel={skipLabel}
        labelIcon={labelIcon}
        registerOnMount={registerOnMount}
        unregisterOnUnmount={unregisterOnUnmount}
        untouchOnUnmount={untouchOnUnmount}
        resetOnUnmount={resetOnUnmount}
        disabled={disabled}
        errorMessageType={errorMessageType}
        showErrorOnUntouched={showErrorOnUntouched}
        showWarningOnUntouched={showWarningOnUntouched}
        skipError={skipError}
      >
        <AntInput
          name={name}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          data-cy={`text.${name}`}
          {...rest}
        />
      </FormItem>
    )
  })
)

const TEXT_DEDFAULT_PROPS: Partial<TextProps> = {
  ...FORM_ITEM_DEFAULT_PROPS,
  status: undefined,
  size: 'large',
}
