import React, {type FC} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Typography} from 'antd'
import cn from 'classnames'
import {observer} from 'mobx-react-lite'
import AppFooter from 'components/AppFooter'
import {LanguageMenu} from 'components/LanguageMenu'
import {ToggleThemeButton} from 'components/ToggleThemeButton'
import errorStyles from 'assets/styles/modules/error.module.scss'
import styles from './index.module.scss'

///////////////////////////////////////////////////////////////////////////////

const NAMESPACE = 'generic-error'
const {Title} = Typography

///////////////////////////////////////////////////////////////////////////////

export const GenericError: FC = observer(() => {
  const {t} = useTranslation(NAMESPACE)
  const title = t('title', {defaultValue: ''})
  const contentHTML = t('content_html', {defaultValue: ''})

  const goToDashboard = () => {
    window.location.href = window.location.pathname
  }

  // todo: log error with 3rd party service
  // const error = useRouteError()

  return (
    <div className={errorStyles.cont}>
      <div className={errorStyles.header}>
        <div className={errorStyles.logo} />
        <ToggleThemeButton />
        <LanguageMenu />
      </div>
      <div className={errorStyles.main}>
        <div className={cn([styles.image, errorStyles.image])} />
        <div className={errorStyles.text}>
          {title && (
            <Title level={2} className={errorStyles.title}>
              {title}
            </Title>
          )}
          {contentHTML && (
            <div
              className={errorStyles.contentHTML}
              dangerouslySetInnerHTML={{__html: contentHTML}}
            />
          )}
          <Button size="large" type="primary" onClick={goToDashboard}>
            {t('buttons.go_home')}
          </Button>
        </div>
      </div>
      <AppFooter />
    </div>
  )
})
