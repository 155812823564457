import React, {
  type Context,
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
} from 'react'
import {observer} from 'mobx-react-lite'

///////////////////////////////////////////////////////////////////////////////

export const createContextProvider = <Props, Value>(
  useValue: (props: Props) => Value
) => {
  const Kontext = createContext<Value>(undefined as unknown as Value)

  const Provider: FC<PropsWithChildren<Props>> = observer(
    ({children, ...props}) => {
      const value = useValue(props as Props)
      return <Kontext.Provider value={value}>{children}</Kontext.Provider>
    }
  )

  const useContextHook = <T = Value,>() =>
    useContext(Kontext as unknown as Context<T>)

  return [Provider, useContextHook] as const
}
